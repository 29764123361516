<template>
  <NeoDrawerLayout :showFooter="true">
    <div class="AddManager">
      <a-form-model v-bind="layout" ref="managerForm" :model="formdata" :rules="rules" :colon="false">
        <!-- 姓名 -->
        <a-form-model-item class="mb-3" prop="name" :label="$t('managerInfo.name')">
          <a-input v-model="formdata.name" :placeholder="$t('managerInfo.name_holder')" :maxLength="10"></a-input>
        </a-form-model-item>
        <!-- 邮箱 -->
        <a-form-model-item class="mb-1" prop="email" :label="$t('managerInfo.email')">
          <a-input
            v-model="formdata.email"
            :placeholder="$t('managerInfo.email_holder')"
            :disabled="edit"
            @blur="checkEmail"
          ></a-input>
          <NeoTips :label="$t('managerInfo.tip_label')" :text="$t('managerInfo.tip_text')" class="tips" />
        </a-form-model-item>
        <!-- 手机 -->
        <a-form-model-item class="mb-3" :label="$t('managerInfo.phone_number')">
          <a-input v-model="formdata.phone" :placeholder="$t('managerInfo.phone_number_holder')"></a-input>
        </a-form-model-item>
        <!-- 部门 -->
        <a-form-model-item class="mb-3" :label="$t('managerInfo.department')">
          <a-input
            v-model="formdata.department"
            :placeholder="$t('managerInfo.department_holder')"
            :maxLength="20"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item class="mb-3" :label="$t('managerInfo.permission')" prop="type">
          <a-select v-model="formdata.type">
            <a-select-option v-for="item in managerList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <template v-slot:footer>
      <a-space :size="10">
        <a-button v-if="edit" type="primary" @click="onEditSubmit">{{ $t('submit') }}</a-button>
        <a-button v-else type="primary" @click="confirmSubmit" :loading="loading">{{ $t('submit') }}</a-button>
      </a-space>
    </template>

    <a-modal
      class="confirm-display-modal"
      :width="480"
      :title="$t('customerConfig.staff_sure')"
      centered
      :visible="confirmSubmitVisible"
      @cancel="confirmSubmitVisible = false"
    >
      <div class="mod">
        <span>{{ $t('customerConfig.sure_staff_tip', { name: $store.state.app.cusAgencyInfo.name }) }}</span>
      </div>

      <template slot="footer">
        <a-button type="primary" @click="sureAddOrderManage">{{ $t('confirm') }}</a-button>
      </template>
    </a-modal>
  </NeoDrawerLayout>
</template>

<script>
import { Icon } from 'ant-design-vue'
import NeoTips from '@/components/common/NeoTips'
const { validate } = window.$g

export default {
  name: 'AddManager',
  components: { NeoTips },
  props: ['id', 'edit', 'managerInfo', 'agencyInfo'],
  data() {
    return {
      loading: false,
      layout: {
        labelCol: { flex: '60px' },
        wrapperCol: { flex: 'auto' },
      },
      formdata: {
        name: '',
        email: '',
        phone: '',
        department: '',
        type: 1,
      },
      rules: {
        name: [validate.required],
        email: [validate.email, validate.no_gt_email, validate.required],
        type: [validate.required],
      },
      default: '',
      managerList: [
        {
          value: 1,
          label: this.$t('managerInfo.manager'),
        },
        {
          value: 3,
          label: this.$t('managerInfo.order_admin'),
        },
      ],
      confirmSubmitVisible: false,
    }
  },
  mounted() {
    if (this.edit) {
      this.getManager()
    }
  },
  computed: {
    gender() {
      return this.$store.getters['app/getDictByType']('GENDER')
    },
  },
  methods: {
    async checkEmail() {
      try {
        const reg = validate.email.pattern
        const check = reg.test(this.formdata.email)
        console.log(`check`, check)
        if (!check) {
          return false
        }
        const params = { email: this.formdata.email }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('checkDeliveryEmail', params)
        console.log(`data`, data)
        if (data) {
          this.$message.error(this.$t('customerConfig.email_exists'))
          return true
        } else {
          return false
        }
      } catch (err) {
        this.$httpNotify(err)
        return true
      }
    },
    confirmSubmit() {
      this.$refs.managerForm.validate(async (valid) => {
        if (valid) {
          if (await this.checkEmail()) {
            return
          }
          this.$confirm({
            title: this.$t('customerConfig.staff_sure'),
            centered: false,
            class: 'coConfig_AddManager_confirm_class',
            icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
            content: this.$t('customerConfig.sure_staff_tip', { name: this.$store.state.app.cusAgencyInfo.name }),
            okText: this.$t('confirm'),
            cancelText: this.$t('cancel'),
            onOk: () => {
              this.onSubmit()
            },
          })
        }
      })
    },
    sureAddOrderManage() {
      this.confirmSubmitVisible = false
      this.onSubmit()
    },
    onSubmit() {
      this.$refs.managerForm.validate(async (valid) => {
        if (valid) {
          try {
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('cusEmployeeSave', {
              ...this.formdata,
            })
            this.$message.success(this.$t('prompt.create_success'))
            this.$emit('show')
          } catch (err) {
            this.$httpNotify(err)
          }
        }
      })
    },
    onEditSubmit() {
      this.$refs.managerForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('cusEmployeeUpdate', {
              id: this.managerInfo.id,
              ...this.formdata,
            })
            this.loading = false
            this.$message.success(this.$t('prompt.edit_success'))
            this.$emit('show')
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    async getManager() {
      try {
        const data = await this.$http({ key: 'cusEmployeeQuery', params: { id: this.managerInfo.id } })
        this.formdata = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.AddManager {
  ::v-deep {
    .ant-form-item {
      display: flex;
    }
  }
}
</style>
<style lang="less">
.coConfig_AddManager_confirm_class {
  .ant-modal {
    top: 30%;
  }
}
</style>
